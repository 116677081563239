import { CookieService } from 'ngx-cookie-service';
import { KeycloakService, KeycloakOptions, KeycloakEventType } from 'keycloak-angular';
import { environment } from './environments/environment';
import axios from 'axios';

export function initializerKeycloak(keycloakService: KeycloakService): () => Promise<any> {
	const options: KeycloakOptions = { 
		config: environment.keycloakConfig,
        initOptions: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/html/silent-check-sso.html',
        },
	}
	return (): Promise<any> => {
        
        try {
            keycloakService.keycloakEvents$.subscribe({
                next: e => {
                  if (e.type == KeycloakEventType.OnTokenExpired) {
                    keycloakService.updateToken(20);
                  }
                }
            });

            axios.interceptors.request.use (
                async request => {
                    //const token = await keycloakService.getToken();
                    const token = await keycloakService.getToken();
                    request.headers['Authorization'] = `Bearer ${token}`;  
                    return request;
                },
                error => {
                    return Promise.reject(error);
                }
                
            );

            axios.interceptors.response.use (
                async response => {
                    return response;
                },
                error => {
                    console.log("axios response error", error);
                    const originalRequest = error.config;
                    //invalid_grant
                    if (error && error.response && error.response.status == 400) {
                        console.log("se vencio el token, generar uno nuevo");
                        //keycloakService.tok
                    }
                    return Promise.reject(error);
                }
                
            );
            return keycloakService.init(options);    
        } catch (error) {
            console.log("EEEEEEEEEEEEEError", error);
        }
        
    }
}
