import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService{

    logoUrl = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
        const fullLogoUrl = localStorage.getItem("logo");
        this.logoUrl.next(fullLogoUrl);
    }

    changeEmpresa = (empresa, userData) => {
        
        localStorage.setItem("tenant", empresa.tenant);

        //console.log("change empresa...................... aqui setear usuario", empresa, userData);

            /*this.usuarioService.getById(userProfile.id).pipe(
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((resultData: any) => {
                //console.log("usuario", resultData);
                if (resultData.success == true) {
                    userData.role = resultData.result.rol;
                    localStorage.setItem('ud', JSON.stringify(userData));

                } 
                
            });*/
        userData.role = empresa.role;
        localStorage.setItem('ud', JSON.stringify(userData));

        this.updateImage();
    }
  
    updateImage = () => {
        

        const logoBase = "assets/images/logos/icon-facturasend.png";
        localStorage.setItem("logo", logoBase);
        
        const tenantId = localStorage.getItem("tenant");

        this.logoUrl.next(logoBase);

        axios.get(`${environment.url}${tenantId}/personalizacion` ).then((response: any) => { 
            
            if(response.data.success){
               
                if (response.data.result.logo_base_64 && response.data.result.logo_base_64.length > 0) {
               
                    localStorage.setItem("logo", response.data.result.logo_base_64);
                    this.logoUrl.next(response.data.result.logo_base_64);
                } else {
                    //Poner imagen (No imagen)
                    //this.urlImage = 
                }
            } else {
            }
        });
    }

    getWebhookList = (tenantId: string) => {
        //if (!(query && query.length > 0)) query = '%20';
        return this.http.get<any>(environment.url + tenantId + '/webhooks/');
    }
    deleteWebhook = (tenantId: string, id: number) => {
        return this.http.delete(environment.url + tenantId + '/webhook/' + id);
    }
}
